/* stylelint-disable property-no-vendor-prefix */
/**
 * Volume slider
 */
/* Remove default focus */
.accessible-video-player__volume-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 15px;
    height: 6px;
    background: linear-gradient(
        to right,
        var(--volume-level-background-color) var(--volume-slider-value),
        var(--volume-track-background-color) var(--volume-slider-value)
    );
}
.accessible-video-player__volume-slider:focus {
    outline: none;
}
.accessible-video-player__volume-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    width: 15px;
    background-color: var(--volume-track-background-color);
    border: 2px solid var(--track-background-color);
    border-radius: 50%;
    transition: 0.2s ease-in-out;
}
.accessible-video-player__volume-slider::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: var(--volume-track-background-color);
    border: 2px solid var(--track-background-color);
    border-radius: 50%;
    transition: 0.2s ease-in-out;
}
.accessible-video-player__volume-slider::-webkit-slider-thumb:hover {
    outline: 2px solid var(--volume-track-background-color);
}
.accessible-video-player__volume-slider:active::-webkit-slider-thumb {
    outline: 2px solid var(--volume-track-background-color);
}
.accessible-video-player__volume-slider:focus-visible::-webkit-slider-thumb {
    box-shadow: 0 0 0 2px var(--focus-ring-inner);
    outline: 2px solid var(--focus-ring-outer);
    outline-offset: 2px;
}
.accessible-video-player__volume-slider::-moz-range-thumb:hover {
    outline: 2px solid var(--volume-track-background-color);
}
.accessible-video-player__volume-slider:active::-moz-range-thumb {
    outline: 2px solid var(--volume-track-background-color);
}
.accessible-video-player__volume-slider:focus-visible::-moz-range-thumb {
    box-shadow: 0 0 0 2px var(--focus-ring-inner);
    outline: 2px solid var(--focus-ring-outer);
    outline-offset: 2px;
}
.accessible-video-player__options-target[data-state="active"] {
    z-index: 2;
    opacity: 1;
}
