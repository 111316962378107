/* @TODO: Rename all variables to include the component name, so they don't clash with other variables in other projects */
/* @TODO: Ensure these styles are scoped (they are not now) */

/**
 * CSS Variable, they can be used to override the default styles.
 */
.accessible-video-player {
    --controls-bar-height: 44px;
    --controls-background-color: #f6f6f6;
    --track-background-color: #061a42;
    --progress-background-color: #5fcdf5;
    --control-icon-color: #061a42;
    --control-option-background-color: #061a42;
    --control-option-text-color: #f6f6f6;
    --icon-hover-color: #2e4e8d;
    --track-border-color: #061a42;
    --play-button-size-small: 44px;
    --play-button-size-medium: 62px;
    --play-button-size-large: 80px;
    --play-button-background-color: #ffffff;
    --play-button-background-color-hover: #030e25;
    --play-button-foreground-color: #030e25;
    --play-button-foreground-color-hover: #ffffff;
    --component-border-radius: 4px;
    --transcription-text-background: #ffffff;
    --transcript-header-background: #f6f6f6;
    --transcript-title-color: #061a42;
    --transcript-text-color: #061a42;
    --control-menu-background-color: #f6f6f6;
    --volume-track-background-color: #f6f6f6;
    --volume-level-background-color: #5fcdf5;
    --time-text-color: #061a42;
    --hover-color: #5fcdf5;
    --focus-ring-inner: #f6f6f6;
    --focus-ring-outer: #061a42;
    --focus-ring-size: 3px;
    --option-offset: 4rem;

    overflow: hidden;
    margin: 0;
    border-radius: var(--component-border-radius);
    box-shadow: 0 0 30px rgb(6 26 66 / 0.25), 0 0 4px rgb(6 26 66 / 0.1);
}

/* Reset project specific styles */
.accessible-video-player button::after {
    // stylelint-disable-next-line declaration-no-important
    content: none !important;
}

/**
 * Main container
 */
.accessible-video-player__inner {
    overflow: hidden;
    position: relative;
    border-radius: var(--component-border-radius);
}

/**
 * Control list - All the buttons and controls of the video
 */
.accessible-video-player__control-list {
    z-index: 888;
    position: relative;
    width: 100%;
    height: var(--controls-bar-height);
    margin: 0;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background: var(--control-menu-background-color);
    list-style-type: none;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}
.accessible-video-player__control-item {
    position: relative;
    display: flex;
    align-items: center;
}
.accessible-video-player__control-item[aria-hidden="true"] {
    display: none;
}
/* Wrapper around some of the icons */
.accessible-video-player__icon-wrapper {
    display: inline-flex;
}
.accessible-video-player__icon-wrapper[aria-hidden="true"] {
    display: none;
}
/**
 * Control Buttons
 * Each button inside the controls list (mute, play etc)
 */
.accessible-video-player__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.35rem;
    background: none;
    box-shadow: none;
    border-radius: 4px;
}
/* Increase the clickable area of the button to be minimum 45px */
.accessible-video-player__button::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 45px;
    min-height: 45px;
    transform: translate(-50%, -50%);
}

.accessible-video-player__button:hover,
.accessible-video-player__button:focus-visible {
    box-shadow: 0 0 0 var(--focus-ring-size) var(--focus-ring-inner);
    outline: var(--focus-ring-size) solid var(--focus-ring-outer);
    outline-offset: var(--focus-ring-size);
}

/* The icon */
.accessible-video-player__button svg {
    fill: var(--control-icon-color);
    width: 20px;
    height: 20px;
}
.accessible-video-player__button:hover svg,
.accessible-video-player__button:focus-visible svg {
    fill: var(--icon-hover-color);
}

/* Active state */
.accessible-video-player__button[data-active="true"] svg {
    fill: var(--icon-hover-color);
}

/**
 * Trigger/Target for options (generic)
 */
.accessible-video-player__options-target,
.accessible-video-player__button-hover-target {
    box-sizing: content-box; // To make max-content work in iOS Safari
    z-index: -1;
    opacity: 0;
    position: absolute;
    bottom: var(--option-offset);
    left: 50%;
    width: max-content;
    max-width: 15rem;
    padding: 1rem;
    font-size: 1.3rem;
    text-align: center;
    background-color: var(--control-option-background-color);
    color: var(--control-option-text-color);
    border-radius: 4px;
    pointer-events: none;
    transform: translateX(-50%);
}
/* Show the popup with the description of the button */
.accessible-video-player__options-target[data-visible="true"],
.accessible-video-player__button-hover-target[data-visible="true"] {
    z-index: 1;
    opacity: 1;
}
.accessible-video-player__options-target[data-state="active"] {
    pointer-events: initial;
}
.accessible-video-player__button-hover-target::after {
    content: "";
    position: absolute;
    bottom: -2rem;
    width: 100%;
    height: 2rem;
    left: 0;
}

.accessible-video-player__volume-container {
    right: auto;
    left: 50%;
    display: flex;
    align-items: center;
    width: 8rem;
    height: 2rem;
    margin-bottom: 3rem;
    transform-origin: 0 50%;
    transform: rotate(270deg) translate(-50%, 0);

    @media (min-width: 760px) {
        width: 10rem;
        height: 3rem;
        margin-bottom: 3.5rem;
    }
}
// Masking rectangle to accommodate hover area
.accessible-video-player__volume-container::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 2rem;
    height: 4rem;
    margin-left: -1em;
    transform: translateY(-50%);
}

/* The two latest popups should be positioned differently */
.accessible-video-player__control-item:nth-last-child(1)
    .accessible-video-player__button-hover-target {
    right: 0;
    left: auto;
    transform: translateX(0);
}
.accessible-video-player__control-item:nth-last-child(2)
    .accessible-video-player__button-hover-target {
    right: -4rem;
    left: auto;
    transform: translateX(0);
}

.accessible-video-player__options-target
    button:not([data-checked="true"])
    span[aria-hidden="true"] {
    opacity: 0;
}
