/**
 * Subtitles
 */
.accessible-video-player__options-target--subtitles[data-state="closed"] {
    pointer-events: none;
}
.accessible-video-player__options-target--subtitles fieldset {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.5rem;
}
.accessible-video-player__options-target--subtitles label {
    font-family: inherit;
    display: flex;
    margin: 0;
    gap: 0.75rem;
    text-align: left;
    font-size: 1.35rem;
    cursor: pointer;
}
.accessible-video-player__options-target--subtitles
    label:hover
    span:last-of-type,
.accessible-video-player__options-target--subtitles
    label:has(input:focus)
    span:last-of-type {
    outline: 2px solid white;
    outline-offset: 2px;
    border-radius: 2px;
}
/* Visually hide input */
.accessible-video-player__options-target--subtitles input {
    position: absolute;
    left: -9999px;
    // stylelint-disable-next-line declaration-no-important
    width: 1px !important;
    // stylelint-disable-next-line declaration-no-important
    height: 1px !important;
}
.accessible-video-player__options-target--subtitles label span:first-of-type {
    opacity: 0;
}
.accessible-video-player__options-target--subtitles label span:last-of-type {
    padding: 0 0.25rem;
}

/* We cannot use :checked here because focus will create a checked state! */
// Checked icon
.accessible-video-player__options-target--subtitles
    input[data-checked="true"]
    ~ span:first-of-type {
    opacity: 1;
}
