/* stylelint-disable property-no-vendor-prefix */
/**
 * Progress timeline
 */
.accessible-video-player__progress-bar-container {
    flex-grow: 1;
    gap: 14px;
    padding: 0 7px;
}

/**
 * Progress bar.
 */
.accessible-video-player__progress-bar {
    display: none;

    @media (min-width: 360px) {
        -webkit-appearance: none;
        appearance: none;
        display: block;
        width: 100%;
        height: 10px;
        background-color: var(--track-background-color);
        background-image: linear-gradient(
            to right,
            var(--progress-background-color) var(--progress-indicator-value),
            var(--track-background-color) var(--progress-indicator-value)
        );
        border: 2px solid var(--track-border-color);
        border-radius: 5px;
    }
}

.accessible-video-player__progress-bar::-webkit-slider-runnable-track {
    height: 10px;
    border-radius: 5px;
}

.accessible-video-player__progress-bar::-moz-range-progress {
    height: 100%;
    background: var(--progress-background-color);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.accessible-video-player__progress-bar::-webkit-slider-thumb {
    opacity: 0;
}

.accessible-video-player__progress-bar::-moz-range-thumb {
    opacity: 0;
}

.accessible-video-player__progress-bar:hover {
    cursor: pointer;
}
.accessible-video-player__progress-bar:focus {
    outline: none;
}
.accessible-video-player__progress-bar:focus-visible {
    box-shadow: 0 0 0 2px var(--focus-ring-inner);
    outline: 2px solid var(--focus-ring-outer);
    outline-offset: 2px;
}

.accessible-video-player__current-time,
.accessible-video-player__max-time {
    color: var(--time-text-color);
    display: none;
    font-size: 1.5rem;

    @media (min-width: 760px) {
        display: block;
    }
}
