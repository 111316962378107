/* @TODO: Rename all variables to include the component name, so they don't clash with other variables in other projects */
/* @TODO: Ensure these styles are scoped (they are not now) */
/**
 * CSS Variable, they can be used to override the default styles.
 */
.accessible-video-player {
  --controls-bar-height: 44px;
  --controls-background-color: #f6f6f6;
  --track-background-color: #061a42;
  --progress-background-color: #5fcdf5;
  --control-icon-color: #061a42;
  --control-option-background-color: #061a42;
  --control-option-text-color: #f6f6f6;
  --icon-hover-color: #2e4e8d;
  --track-border-color: #061a42;
  --play-button-size-small: 44px;
  --play-button-size-medium: 62px;
  --play-button-size-large: 80px;
  --play-button-background-color: #ffffff;
  --play-button-background-color-hover: #030e25;
  --play-button-foreground-color: #030e25;
  --play-button-foreground-color-hover: #ffffff;
  --component-border-radius: 4px;
  --transcription-text-background: #ffffff;
  --transcript-header-background: #f6f6f6;
  --transcript-title-color: #061a42;
  --transcript-text-color: #061a42;
  --control-menu-background-color: #f6f6f6;
  --volume-track-background-color: #f6f6f6;
  --volume-level-background-color: #5fcdf5;
  --time-text-color: #061a42;
  --hover-color: #5fcdf5;
  --focus-ring-inner: #f6f6f6;
  --focus-ring-outer: #061a42;
  --focus-ring-size: 3px;
  --option-offset: 4rem;
  overflow: hidden;
  margin: 0;
  border-radius: var(--component-border-radius);
  box-shadow: 0 0 30px rgba(6, 26, 66, 0.25), 0 0 4px rgba(6, 26, 66, 0.1);
}

/* Reset project specific styles */
.accessible-video-player button::after {
  content: none !important;
}

/**
 * Main container
 */
.accessible-video-player__inner {
  overflow: hidden;
  position: relative;
  border-radius: var(--component-border-radius);
}

/**
 * Control list - All the buttons and controls of the video
 */
.accessible-video-player__control-list {
  z-index: 888;
  position: relative;
  width: 100%;
  height: var(--controls-bar-height);
  margin: 0;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: var(--control-menu-background-color);
  list-style-type: none;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.accessible-video-player__control-item {
  position: relative;
  display: flex;
  align-items: center;
}

.accessible-video-player__control-item[aria-hidden=true] {
  display: none;
}

/* Wrapper around some of the icons */
.accessible-video-player__icon-wrapper {
  display: inline-flex;
}

.accessible-video-player__icon-wrapper[aria-hidden=true] {
  display: none;
}

/**
 * Control Buttons
 * Each button inside the controls list (mute, play etc)
 */
.accessible-video-player__button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.35rem;
  background: none;
  box-shadow: none;
  border-radius: 4px;
}

/* Increase the clickable area of the button to be minimum 45px */
.accessible-video-player__button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 45px;
  min-height: 45px;
  transform: translate(-50%, -50%);
}

.accessible-video-player__button:hover,
.accessible-video-player__button:focus-visible {
  box-shadow: 0 0 0 var(--focus-ring-size) var(--focus-ring-inner);
  outline: var(--focus-ring-size) solid var(--focus-ring-outer);
  outline-offset: var(--focus-ring-size);
}

/* The icon */
.accessible-video-player__button svg {
  fill: var(--control-icon-color);
  width: 20px;
  height: 20px;
}

.accessible-video-player__button:hover svg,
.accessible-video-player__button:focus-visible svg {
  fill: var(--icon-hover-color);
}

/* Active state */
.accessible-video-player__button[data-active=true] svg {
  fill: var(--icon-hover-color);
}

/**
 * Trigger/Target for options (generic)
 */
.accessible-video-player__options-target,
.accessible-video-player__button-hover-target {
  box-sizing: content-box;
  z-index: -1;
  opacity: 0;
  position: absolute;
  bottom: var(--option-offset);
  left: 50%;
  width: max-content;
  max-width: 15rem;
  padding: 1rem;
  font-size: 1.3rem;
  text-align: center;
  background-color: var(--control-option-background-color);
  color: var(--control-option-text-color);
  border-radius: 4px;
  pointer-events: none;
  transform: translateX(-50%);
}

/* Show the popup with the description of the button */
.accessible-video-player__options-target[data-visible=true],
.accessible-video-player__button-hover-target[data-visible=true] {
  z-index: 1;
  opacity: 1;
}

.accessible-video-player__options-target[data-state=active] {
  pointer-events: initial;
}

.accessible-video-player__button-hover-target::after {
  content: "";
  position: absolute;
  bottom: -2rem;
  width: 100%;
  height: 2rem;
  left: 0;
}

.accessible-video-player__volume-container {
  right: auto;
  left: 50%;
  display: flex;
  align-items: center;
  width: 8rem;
  height: 2rem;
  margin-bottom: 3rem;
  transform-origin: 0 50%;
  transform: rotate(270deg) translate(-50%, 0);
}
@media (min-width: 760px) {
  .accessible-video-player__volume-container {
    width: 10rem;
    height: 3rem;
    margin-bottom: 3.5rem;
  }
}

.accessible-video-player__volume-container::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 2rem;
  height: 4rem;
  margin-left: -1em;
  transform: translateY(-50%);
}

/* The two latest popups should be positioned differently */
.accessible-video-player__control-item:nth-last-child(1) .accessible-video-player__button-hover-target {
  right: 0;
  left: auto;
  transform: translateX(0);
}

.accessible-video-player__control-item:nth-last-child(2) .accessible-video-player__button-hover-target {
  right: -4rem;
  left: auto;
  transform: translateX(0);
}

.accessible-video-player__options-target button:not([data-checked=true]) span[aria-hidden=true] {
  opacity: 0;
}

/**
 * Initial play button (placed in the center of the video)
 */
.accessible-video-player__initial-play-button-wrapper {
  z-index: 1;
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(100% - 8px);
  height: calc(100% - var(--play-button-size-small) - 8px);
}

.accessible-video-player__initial-play-button {
  position: absolute;
  top: calc(50% - var(--play-button-size-small));
  left: 50%;
  width: var(--play-button-size-small);
  height: var(--play-button-size-small);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: var(--play-button-background-color);
  border-radius: 50%;
  box-shadow: none;
  transform: translate(-50%, calc(-50% + var(--play-button-size-small)));
}
@media (min-width: 680px) {
  .accessible-video-player__initial-play-button {
    width: var(--play-button-size-medium);
    height: var(--play-button-size-medium);
  }
}
@media (min-width: 990px) {
  .accessible-video-player__initial-play-button {
    width: var(--play-button-size-large);
    height: var(--play-button-size-large);
  }
}

.accessible-video-player__initial-play-button:hover {
  background: var(--play-button-background-color-hover);
}

.accessible-video-player__initial-play-button:focus-visible {
  box-shadow: 0 0 0 var(--focus-ring-size) var(--focus-ring-inner);
  outline: var(--focus-ring-size) solid var(--focus-ring-outer);
  outline-offset: var(--focus-ring-size);
}

/* When the button should be hidden */
.accessible-video-player__initial-play-button[aria-hidden=true] {
  display: none;
}

/* The play icon */
.accessible-video-player__initial-play-button > svg {
  width: 30%;
  height: 30%;
  color: var(--play-button-foreground-color);
}

.accessible-video-player__initial-play-button:hover > svg {
  color: var(--play-button-foreground-color-hover);
}

/* stylelint-disable property-no-vendor-prefix */
/**
 * Progress timeline
 */
.accessible-video-player__progress-bar-container {
  flex-grow: 1;
  gap: 14px;
  padding: 0 7px;
}

/**
 * Progress bar.
 */
.accessible-video-player__progress-bar {
  display: none;
}
@media (min-width: 360px) {
  .accessible-video-player__progress-bar {
    -webkit-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: 10px;
    background-color: var(--track-background-color);
    background-image: linear-gradient(to right, var(--progress-background-color) var(--progress-indicator-value), var(--track-background-color) var(--progress-indicator-value));
    border: 2px solid var(--track-border-color);
    border-radius: 5px;
  }
}

.accessible-video-player__progress-bar::-webkit-slider-runnable-track {
  height: 10px;
  border-radius: 5px;
}

.accessible-video-player__progress-bar::-moz-range-progress {
  height: 100%;
  background: var(--progress-background-color);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.accessible-video-player__progress-bar::-webkit-slider-thumb {
  opacity: 0;
}

.accessible-video-player__progress-bar::-moz-range-thumb {
  opacity: 0;
}

.accessible-video-player__progress-bar:hover {
  cursor: pointer;
}

.accessible-video-player__progress-bar:focus {
  outline: none;
}

.accessible-video-player__progress-bar:focus-visible {
  box-shadow: 0 0 0 2px var(--focus-ring-inner);
  outline: 2px solid var(--focus-ring-outer);
  outline-offset: 2px;
}

.accessible-video-player__current-time,
.accessible-video-player__max-time {
  color: var(--time-text-color);
  display: none;
  font-size: 1.5rem;
}
@media (min-width: 760px) {
  .accessible-video-player__current-time,
  .accessible-video-player__max-time {
    display: block;
  }
}

/**
 * Subtitles
 */
.accessible-video-player__options-target--subtitles[data-state=closed] {
  pointer-events: none;
}

.accessible-video-player__options-target--subtitles fieldset {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.5rem;
}

.accessible-video-player__options-target--subtitles label {
  font-family: inherit;
  display: flex;
  margin: 0;
  gap: 0.75rem;
  text-align: left;
  font-size: 1.35rem;
  cursor: pointer;
}

.accessible-video-player__options-target--subtitles label:hover span:last-of-type,
.accessible-video-player__options-target--subtitles label:has(input:focus) span:last-of-type {
  outline: 2px solid white;
  outline-offset: 2px;
  border-radius: 2px;
}

/* Visually hide input */
.accessible-video-player__options-target--subtitles input {
  position: absolute;
  left: -9999px;
  width: 1px !important;
  height: 1px !important;
}

.accessible-video-player__options-target--subtitles label span:first-of-type {
  opacity: 0;
}

.accessible-video-player__options-target--subtitles label span:last-of-type {
  padding: 0 0.25rem;
}

/* We cannot use :checked here because focus will create a checked state! */
.accessible-video-player__options-target--subtitles input[data-checked=true] ~ span:first-of-type {
  opacity: 1;
}

/**
 * Transcript
 */
.accessible-video-player__transcript {
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--component-border-radius);
}

.accessible-video-player__transcript[aria-hidden=true] {
  display: none;
}

.accessible-video-player__transcript-inner {
  overflow: scroll;
  max-height: 335px;
  padding: 2em 3em;
  background: var(--transcription-text-background);
  text-align: left;
}

.accessible-video-player__transcript-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--transcript-header-background);
  padding: 19px 20px;
}
@media (min-width: 760px) {
  .accessible-video-player__transcript-header {
    padding: 20px 25px;
  }
}

.accessible-video-player__transcript-title {
  margin: 0;
  font-size: 1em;
  color: var(--transcript-title-color);
}

.accessible-video-player__transcript-text {
  max-width: 27.5em;
  color: var(--transcript-text-color);
}

.accessible-video-player__transcript-text > * {
  margin-top: 0;
  margin-bottom: 1em;
}

.accessible-video-player__transcript-text > :last-child {
  margin-bottom: 0;
}

.accessible-video-player__transcript-close {
  position: relative;
  padding: 0;
  background: none;
  box-shadow: none;
}

.accessible-video-player__transcript-close:focus {
  outline: none;
}

.accessible-video-player__transcript-close::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 44px;
  min-height: 44px;
  border-radius: var(--component-border-radius);
  transform: translate(-50%, -50%);
}

.accessible-video-player__transcript-close:focus-visible::before {
  box-shadow: 0 0 0 var(--focus-ring-size) var(--focus-ring-inner);
  outline: var(--focus-ring-size) solid var(--focus-ring-outer);
  outline-offset: var(--focus-ring-size);
  border-radius: inherit;
}

.accessible-video-player__transcript-close > svg {
  display: block;
  padding: 0;
  height: 14px;
  width: 14px;
}

.accessible-video-player__transcript-close:hover::before {
  background: rgba(0, 0, 0, 0.065);
}

/**
 * Video element
 */
.accessible-video-player__video {
  width: 100%;
  display: block;
}

/* stylelint-disable property-no-vendor-prefix */
/**
 * Volume slider
 */
/* Remove default focus */
.accessible-video-player__volume-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 6px;
  background: linear-gradient(to right, var(--volume-level-background-color) var(--volume-slider-value), var(--volume-track-background-color) var(--volume-slider-value));
}

.accessible-video-player__volume-slider:focus {
  outline: none;
}

.accessible-video-player__volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: var(--volume-track-background-color);
  border: 2px solid var(--track-background-color);
  border-radius: 50%;
  transition: 0.2s ease-in-out;
}

.accessible-video-player__volume-slider::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: var(--volume-track-background-color);
  border: 2px solid var(--track-background-color);
  border-radius: 50%;
  transition: 0.2s ease-in-out;
}

.accessible-video-player__volume-slider::-webkit-slider-thumb:hover {
  outline: 2px solid var(--volume-track-background-color);
}

.accessible-video-player__volume-slider:active::-webkit-slider-thumb {
  outline: 2px solid var(--volume-track-background-color);
}

.accessible-video-player__volume-slider:focus-visible::-webkit-slider-thumb {
  box-shadow: 0 0 0 2px var(--focus-ring-inner);
  outline: 2px solid var(--focus-ring-outer);
  outline-offset: 2px;
}

.accessible-video-player__volume-slider::-moz-range-thumb:hover {
  outline: 2px solid var(--volume-track-background-color);
}

.accessible-video-player__volume-slider:active::-moz-range-thumb {
  outline: 2px solid var(--volume-track-background-color);
}

.accessible-video-player__volume-slider:focus-visible::-moz-range-thumb {
  box-shadow: 0 0 0 2px var(--focus-ring-inner);
  outline: 2px solid var(--focus-ring-outer);
  outline-offset: 2px;
}

.accessible-video-player__options-target[data-state=active] {
  z-index: 2;
  opacity: 1;
}