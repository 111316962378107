/**
 * Transcript
 */
.accessible-video-player__transcript {
    overflow: hidden;
    border-top: 1px solid rgb(0 0 0 / 0.1);
    border-radius: var(--component-border-radius);
}
.accessible-video-player__transcript[aria-hidden="true"] {
    display: none;
}
.accessible-video-player__transcript-inner {
    overflow: scroll;
    max-height: 335px;
    padding: 2em 3em;
    background: var(--transcription-text-background);
    text-align: left;
}
.accessible-video-player__transcript-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--transcript-header-background);
    padding: 19px 20px;

    @media (min-width: 760px) {
        padding: 20px 25px;
    }
}
.accessible-video-player__transcript-title {
    margin: 0;
    font-size: 1em;
    color: var(--transcript-title-color);
}
.accessible-video-player__transcript-text {
    max-width: 27.5em;
    color: var(--transcript-text-color);
}

// Reset possible rich text styles
// stylelint-disable selector-max-universal
.accessible-video-player__transcript-text > * {
    margin-top: 0;
    margin-bottom: 1em;
}
.accessible-video-player__transcript-text > :last-child {
    margin-bottom: 0;
}
.accessible-video-player__transcript-close {
    position: relative;
    padding: 0;
    background: none;
    box-shadow: none;
}

.accessible-video-player__transcript-close:focus {
    outline: none;
}

.accessible-video-player__transcript-close::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 44px;
    min-height: 44px;
    border-radius: var(--component-border-radius);
    transform: translate(-50%, -50%);
}

.accessible-video-player__transcript-close:focus-visible::before {
    box-shadow: 0 0 0 var(--focus-ring-size) var(--focus-ring-inner);
    outline: var(--focus-ring-size) solid var(--focus-ring-outer);
    outline-offset: var(--focus-ring-size);
    border-radius: inherit;
}

.accessible-video-player__transcript-close > svg {
    display: block;
    padding: 0;
    height: 14px;
    width: 14px;
}
.accessible-video-player__transcript-close:hover::before {
    background: rgb(0 0 0 / 0.065);
}
