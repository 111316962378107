/**
 * Initial play button (placed in the center of the video)
 */
.accessible-video-player__initial-play-button-wrapper {
    z-index: 1;
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(100% - 8px); // 100% minus offset * 2
    height: calc(
        100% - var(--play-button-size-small) - 8px
    ); // 100% minus height of controls minus offset * 2
}

.accessible-video-player__initial-play-button {
    position: absolute;
    top: calc(50% - var(--play-button-size-small));
    left: 50%;
    width: var(--play-button-size-small);
    height: var(--play-button-size-small);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: var(--play-button-background-color);
    border-radius: 50%;
    box-shadow: none;
    transform: translate(-50%, calc(-50% + var(--play-button-size-small)));

    @media (min-width: 680px) {
        width: var(--play-button-size-medium);
        height: var(--play-button-size-medium);
    }

    @media (min-width: 990px) {
        width: var(--play-button-size-large);
        height: var(--play-button-size-large);
    }
}

.accessible-video-player__initial-play-button:hover {
    background: var(--play-button-background-color-hover);
}

.accessible-video-player__initial-play-button:focus-visible {
    box-shadow: 0 0 0 var(--focus-ring-size) var(--focus-ring-inner);
    outline: var(--focus-ring-size) solid var(--focus-ring-outer);
    outline-offset: var(--focus-ring-size);
}

/* When the button should be hidden */
.accessible-video-player__initial-play-button[aria-hidden="true"] {
    display: none;
}
/* The play icon */
.accessible-video-player__initial-play-button > svg {
    width: 30%;
    height: 30%;
    color: var(--play-button-foreground-color);
}

.accessible-video-player__initial-play-button:hover > svg {
    color: var(--play-button-foreground-color-hover);
}
